// --swiper-navigation-color: #fff;

.swiper-button-prev.swiper-button-white,
.swiper-button-next.swiper-button-white,
.swiper-pagination.swiper-pagination-white,
.swiper-pagination-bullet.swiper-pagination-bullet-active {
  color: #fff;
}

.swiper-button-prev.swiper-button-black,
.swiper-button-next.swiper-button-black {
  color: black;
}
